<header class="header-area">

    <!-- Top Header -->
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <ul class="top-header-social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>

                <div class="col-lg-8 col-md-12">
                    <ul class="top-header-contact-info">
                        <li><i class='bx bx-map'></i> Marketed By : Pil4u.com, Wanaparthy.</li>
                        <li><i class='bx bx-phone-call'></i> info@pil4u.com <a href="tel:++91 770 298 7668">+91 770 298 7668</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- End Top Header -->

    <!-- Start Navbar Area -->
    <nav class="navbar onepage-navbar navbar-expand-lg navbar-light">
        <div class="container">
            <a class="navbar-brand" routerLink="/ "><img src="assets/img/New-Logo-Aduri-2-1.png" alt="logo" style="
                width: 250px;"></a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <div class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </div>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a href="#home" class="nav-link">Home</a></li>
                    <li class="nav-item"><a href="#about" class="nav-link">About</a></li>
                    <li class="nav-item"><a href="#Our-Ventures" class="nav-link">Our Ventures</a></li>
                    <li class="nav-item"><a href="#projects" class="nav-link">Projects</a></li>
                    <!-- <li class="nav-item"><a href="#faq" class="nav-link">FAQ</a></li>
                      <li class="nav-item"><a href="#doctors" class="nav-link">Doctors</a></li> -->
                    <li class="nav-item"><a href="#blog" class="nav-link">Blog</a></li>
                    <li class="nav-item"><a href="#contact" class="nav-link">Contact</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- End Navbar Area -->
    
</header>