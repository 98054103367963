<app-header-one></app-header-one>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>FAQ's</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>FAQ's</li>
            </ul>
        </div>
    </div>
</section>

<section class="faq-section-two ptb-100">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-book-reader'></i><span>COVID-19 Basics</span></a></li>
                <li><a href="#"><i class='bx bx-command'></i><span>Prevention</span></a></li>
                <li><a href="#"><i class='bx bx-user-check'></i><span>Self Quarantine</span></a></li>
                <li><a href="#"><i class='bx bx-user-pin'></i><span>For Caregivers</span></a></li>
                <li><a href="#"><i class='bx bx-info-square'></i><span>Terms to Know</span></a></li>
            </ul>
            <div class="tab-content">
                <div class="tabs-item">
                    <div class="faq-accordion accordion" id="faqAccordion">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    What is the source of the virus? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How does the virus spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Who has had COVID-19 spread the illness to others? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    What is community spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Will warm weather stop the outbreak of COVID-19? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs-item">
                    <div class="faq-accordion accordion" id="faqAccordion2">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                    What is coronavirus? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseSix" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    What is COVID-19? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    What are the symptoms of COVID-19? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    What should I do if I feel sick? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    If a loved one gets sick, how can I care for them? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs-item">
                    <div class="faq-accordion accordion" id="faqAccordion3">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                                    What is the source of the virus? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseEleven" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                    How does the virus spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwelve" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                    Who has had COVID-19 spread the illness to others? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseThirteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                    What is community spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseFourteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                    Will warm weather stop the outbreak of COVID-19? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseFifteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs-item">
                    <div class="faq-accordion accordion" id="faqAccordion4">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="true" aria-controls="collapseSixteen">
                                    What is the source of the virus? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseSixteen" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion4">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                    How does the virus spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseSeventeen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion4">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                    Who has had COVID-19 spread the illness to others? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseEighteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion4">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                    What is community spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseNineteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion4">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                    Will warm weather stop the outbreak of COVID-19? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwenty" class="accordion-collapse collapse" data-bs-parent="#faqAccordion4">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs-item">
                    <div class="faq-accordion accordion" id="faqAccordion5">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyOne" aria-expanded="true" aria-controls="collapseTwentyOne">
                                    What is the source of the virus? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwentyOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion5">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
                                    How does the virus spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwentyTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion5">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                                    Who has had COVID-19 spread the illness to others? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwentyThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion5">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                                    What is community spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwentyFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion5">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                                    Will warm weather stop the outbreak of COVID-19? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwentyFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion5">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="emergency-contact-area pb-100">
    <div class="container">
        <div class="emergency-contact-inner">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="btn-box">
                        <a routerLink="/" class="default-btn"><i class="flaticon-open-book"></i> Request Appointment</a>
                        <a routerLink="/" class="default-btn"><i class="flaticon-doctor"></i> Meet The Doctor</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="contact-cta">
                        <a href="tel:+44587154756">
                            <i class="flaticon-call"></i>
                            <span>Emergency Contact</span>
                            +44 587 154756
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>