<footer class="footer-area">
    <div class="container">
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <h2>Subscribe To Our Newsletter</h2>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL">
                        <button type="submit">Subscribe Now <i class="flaticon-plane"></i></button>
                    </form>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/New-Logo-Aduri-2-1.png" alt="logo" style="width: 250px;" >
                    </a>
                    <p>Real estate was made affordable and profitable with Adhuri’s remarkable land speculation skills.</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Our Ventures</a></li>
                        <li><a routerLink="/">Blog</a></li>
                        <li><a routerLink="/">Projects</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contacts</h3>
                    <ul class="footer-contact-info">
                      <!--  <li><span>Address:</span> 3rd & 4th Floor, Plot No 14, Lodha Rd, KPHB 5th Phase, Kukatpally, Hyderabad, Telangana 500086</li> -->
                        <li><span>Marketed By:</span>  PIL4U.COM, Wanaparthy </li>
                        <li><span>Email:</span> <a href="mailto:hello@qovid.com">info@pil4u.com</a></li>
                        <li><span>Phone:</span> <a href="tel:+919502931499">+91 770 298 7668</a></li>
                        <li><span>Fax:</span> <a href="tel:+919502931499">+91 770 298 7668</a></li>
                    </ul>
                </div>
            </div>

           <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Working Days</h3>
                    <ul class="footer-working-days">
                        <li><span>Monday:</span> 8AM - 6PM</li>
                        <li><span>Tuesday:</span> 8AM - 6PM</li>
                        <li><span>Wednesday:</span> 8AM - 6PM</li>
                        <li><span>Thursday - Friday:</span> 8AM - 6PM</li>
                        <li><span>Sunday:</span> 8AM - 6PM</li>
                    </ul>
                </div> 
            </div> -->
        </div>
    </div>

    <div class="footer-bottom-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© PIl4U Designed  by <a href="https://pil4u.com/" target="_blank">pil4u</a></p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

<!--<div class="footer-shape1"><img src="assets/img/footer-shape1.png" alt="image"></div>
    <div class="footer-shape2"><img src="assets/img/footer-shape2.png" alt="image"></div>
    <div class="footer-shape3"><img src="assets/img/footer-shape3.png" alt="image"></div> -->
</footer>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            
            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

<app-live-data></app-live-data>

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>