<!-- <app-header-two></app-header-two> -->
 <app-onepage-navbar></app-onepage-navbar> 

<!--  <section class="banner-section banner-bg3"> -->
 <div class="home-slides-two owl-carousel owl-theme">
    <div class="banner-section banner-bg6">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <span class="sub-title">RK Enclave </span>
                    <h1>Hyderabad-Warangal Highway</h1>
                    <p>Aduri Group is a Trusted by Happy customers in the last 15 Years.</p>

                    <div class="btn-box">
                        <!-- <a routerLink="/about-two" class="default-btn"><i class="flaticon-open-book"></i> Learn More</a> -->
                        <a href="https://www.youtube.com/watch?v=puNmRq5JuoY" class="popup-youtube optional-btn"><i class="flaticon-play-button"></i> Watch Video</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="main-banner-image">
                   <!-- <img src="assets/img/banner-img4.png" alt="image"> -->
                </div>
            </div>
        </div>
    </div>
  </div>
<!-- banner 02 -->
<div class="banner-section banner-bg7">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <span class="sub-title">RK Enclave </span>
                    <h1>Hyderabad-Warangal Highway</h1>
                    <p>Aduri Group is a Trusted by Happy customers in the last 15 Years.</p>

                    <div class="btn-box">
                        <!-- <a routerLink="/about-two" class="default-btn"><i class="flaticon-open-book"></i> Learn More</a> -->
                        <a href="https://www.youtube.com/watch?v=puNmRq5JuoY" class="popup-youtube optional-btn"><i class="flaticon-play-button"></i> Watch Video</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="main-banner-image">
                   <!-- <img src="assets/img/banner-img4.png" alt="image"> -->
                </div>
            </div>
        </div>
    </div>
  </div>
  <!-- end banner 2 -->
  <div class="banner-section banner-bg8">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <span class="sub-title">RK & RYAN Enclave</span>
                    <h1>Hyderabad-Warangal Highway</h1>
                    <p>Aduri Group is a Trusted by Happy customers in the last 15 Years.</p>

                    <div class="btn-box">
                        <!-- <a routerLink="/about-two" class="default-btn"><i class="flaticon-open-book"></i> Learn More</a> -->
                        <a href="https://www.youtube.com/watch?v=nyxCUXFT2oM" class="popup-youtube optional-btn"><i class="flaticon-play-button"></i> Watch Video</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="main-banner-image">
                   <!-- <img src="assets/img/banner-img4.png" alt="image"> -->
                </div>
            </div>
        </div>
    </div>
  </div>
  <!--end banner 3 -->

  
</div>
<!--  </section> -->

<section class="how-to-spread-area bg-white-color pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Hyderabad-Warangal Highway </span>
            <h2>RK & RYAN ENCLAVE</h2>
        </div>

        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="/assets/img/rk&ryanbr.jpg" class="main-image" alt="image">

                    <div class="shape">
                        <!-- <img src="assets/img/aduri_Icon.png" alt="image" style="width: 30px;"> 
                        <img src="assets/img/aduri_Icon.png" alt="image">  -->
                    </div>
                </div>
            </div>

            <div class="overview-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/aduri_Icon.png" alt="image" style="width: 60px;">
                    </div>
                    <h3>RK & RYAN Enclave </h3>
                    <p>HMDA Approved layout with clear title , Entrance gate with designed arch , Electricity with street lights  </p>
                    <p>Underground Drainage, Water pipe connection to each plot </p>
                    <p>150 feet 100 feet B.T. Roads and 40 feeet C.C. Roads ,Avenuew plantation with premium landscaping  </p>
                    <p>Overhead water tank, Good Ground Water, 100 % Vastu,Children's park and play area,Compound wall, </p> 
                    <p>24x7 Security  </p>
                    <p> <a href="/assets/img/RK & ARYAN Enclave.pdf" class="details-btn" target="_blank" rel="noopener noreferrer"> <button _ngcontent-yhf-c37="" type="Download" > Download Layout </button></a>.</p>
       
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="overview-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/aduri_Icon.png" alt="image" style="width: 60px;">
                    </div>
                    <h3>RYAN Enclave @ bibinagar</h3>
                    <p>HMDA Approved layout with clear title , Entrance gate with designed arch , Electricity with street lights  </p>
                    <p>Underground Drainage, Water pipe connection to each plot </p>
                    <p>150 feet 100 feet B.T. Roads and 40 feeet C.C. Roads ,Avenuew plantation with premium landscaping  </p>
                    <p>Overhead water tank, Good Ground Water, 100 % Vastu,Children's park and play area,Compound wall, </p> 
                    <p>24x7 Security  </p>
                    <p> <a href="/assets/img/RK & ARYAN Enclave.pdf" class="details-btn" target="_blank" rel="noopener noreferrer"> <button _ngcontent-yhf-c37="" type="Download" > Download Layout </button></a>.</p>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="/assets/img/RkLayout.jpg" class="main-image" alt="image">

                    <div class="shape">
                        <!-- <img src="assets/img/circle-shape.png" alt="image">
                        <img src="assets/img/circle-shape2.png" alt="image"> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="/assets/img/RyanLayout.jpg" class="main-image" alt="image">

                    <div class="shape">
                        <!-- <img src="assets/img/circle-shape.png" alt="image">
                        <img src="assets/img/circle-shape2.png" alt="image"> -->
                    </div>
                </div>
            </div>

            <div class="overview-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/aduri_Icon.png" alt="image" style="width: 60px;">
                    </div>
                    <h3>RK Enclave @ Ghatksar </h3>
                    <p>HMDA Approved layout with clear title , Entrance gate with designed arch , Electricity with street lights  </p>
                    <p>Underground Drainage, Water pipe connection to each plot </p>
                    <p>150 feet 100 feet B.T. Roads and 40 feeet C.C. Roads ,Avenuew plantation with premium landscaping  </p>
                    <p>Overhead water tank, Good Ground Water, 100 % Vastu,Children's park and play area,Compound wall, </p> 
                    <p>24x7 Security  </p>
                    <p> <a href="/assets/img/RK & ARYAN Enclave.pdf" class="details-btn" target="_blank" rel="noopener noreferrer"> <button _ngcontent-yhf-c37="" type="Download" > Download Layout </button></a>.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="spread-shape1"><img src="assets/img/spread-shape1.png" alt="image"></div>
    <div class="spread-shape2"><img src="assets/img/spread-shape2.png" alt="image"></div>
</section>

<!-- <section class="symptoms-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="symptoms-image">
                    <img src="assets/img/symptoms-img.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="symptoms-content">
                    <span class="sub-title">Symptoms</span>
                    <h2>Coronavirus Symptoms</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <ul>
                        <li><span><i class='flaticon-tick'></i> Cough</span></li>
                        <li><span><i class='flaticon-tick'></i> Fever</span></li>
                        <li><span><i class='flaticon-tick'></i> Tiredness</span></li>
                        <li><span><i class='flaticon-tick'></i> Headache</span></li>
                        <li><span><i class='flaticon-tick'></i> Breath Shortness</span></li>
                        <li><span><i class='flaticon-tick'></i> Muscle Pain</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="how-to-protect-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Aduri Group</span>
            <h2>Location Highlights </h2>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="how-to-protect-content-list">
                    <div class="content">
                        <div class="text">
                            <div class="icon">
                               <!-- <i class="flaticon-hand-wash"></i> -->
                            </div>
                            <h3>Highlights </h3>
                            <p>Just 1 Km distance from Warangal Higway NH 163.</p>
                            <p>AIIMS Medical University 200 acres.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <!-- <i class="flaticon-people"></i> -->
                            </div>
                            <h3>Highlights</h3>
                            <p>2 Km distance from AIIMS.</p>
                            <p>2 Min. drive to bibinagar MMTS.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                               <!-- <i class="flaticon-home-plan"></i> -->
                            </div>
                            <h3>Highlights</h3>
                            <p>2.2 km Prop. Bhongir & B.B. Nagar Railway HUB.</p>
                            <p>Metro train & MMTS @ Yadadri Temple.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                               <!-- <i class="flaticon-face-detection"></i> -->
                            </div>
                            <h3>Highlights</h3>
                            <p>Surrounded by Conncept schoools, Engineering, B Pharma, B.Ed., & MCA Colleges.</p>
                            <p>Bhogir Fort, Deer Park.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon"> 
                               <!-- <i class="flaticon-mask"></i> -->
                            </div>
                            <h3>Highlights </h3>
                            <p>Raheja IT Park & Infosys campus near by.</p>
                            <p>Singapore township near by .</p>
                            <p>Site located 10Kms Outer Ring Road </p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                               <!-- <i class="flaticon-network"></i> -->
                            </div>
                            <h3>Highlights</h3>
                            <p>30 Min drive to Uppal X Road .</p>
                            <p>35 min Drive to international Airport.</p>
                  
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-7 col-md-12">
                <div class="home-slides-two owl-carousel owl-theme">
                <div class="how-to-protect-img">
                    <img src="assets/img/RkEn-01.jpeg" alt="image">
                </div>
                <div class="how-to-protect-img">
                    <img src="assets/img/RkEn-02.jpeg" alt="image">
                </div>
                <div class="how-to-protect-img">
                    <img src="assets/img/RkEn-03.jpeg" alt="image">
                </div>
                <div class="how-to-protect-img">
                    <img src="assets/img/RkEn-04.jpeg" alt="image">
                </div>
                <div class="how-to-protect-img">
                    <img src="assets/img/RkEn-05.jpeg" alt="image">
                </div>
                <div class="how-to-protect-img">
                    <img src="assets/img/RkEn-06.jpeg" alt="image">
                </div>
                <div class="how-to-protect-img">
                    <img src="assets/img/RkEn-07.jpeg" alt="image">
                </div>
                <div class="how-to-protect-img">
                    <img src="assets/img/RkEn-08.jpeg" alt="image">
                </div>
                <div class="how-to-protect-img">
                    <img src="assets/img/RkEnclaveBn.jpg" alt="image">
                </div>
                <div class="how-to-protect-img">
                    <img src="assets/img/Rkenclavebn2.jpg" alt="image">
                </div>
            </div>
            
          </div>
        </div>
    </div>
</section>

<section class="cta-area pb-100">
    <div class="container">
        <div class="cta-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-md-5">
                            <img src="assets/img/Logo-01.JPG" alt="image">
                        </div>

                        <div class="col-lg-7 col-md-7">
                            <h3>We Operate 24h a day - Every Day!</h3>
                            <p>Call us .</p>
                        <div class="cta-btn"> 
                            <p> <a href="/assets/img/Aduri Group-Company Profile.pdf" class="details-btn" target="_blank" rel="noopener noreferrer">  <span> Download </span>  Aduri Profile </a></p>
                            
                        </div>
                    </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="cta-btn">
                        <a href="tel:+91 770 298 7668">
                            <i class="flaticon-call"></i>
                            <span> Contact</span>
                            +91 770 298 7668
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Aduri Group Blog </span>
            <h2>Aduri Projects </h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-image faq-bg1">
                   <img src="assets/img/faq-img.jpg" alt="image"> 

                    <a href="https://www.youtube.com/watch?v=AXvlwktbBfE" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion accordion" id="faqAccordion">
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                CLEAR TITLE PLOTS <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>The clear title plots by Aduri Group offer you a hassle-free buying experience with immediate registration.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                PRIME LOCATIONS <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Every project by Aduri Group is located intelligently in the most prime locations in and around Hyderabad.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                HIGHER RETURNS <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Aduri Group claims to help or clients double their money within a reasonable time frame. because its potential for growth.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                TRUSTED BY CUSTOMERS <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Aduri Group is a Trusted by Happy customers in the last 20 Years.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                COMMITMENT <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Our commitment is the pathway to achieving our vision. We give 100% commitment to meeting our customer's requirement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

   <!-- <div class="faq-shape1"><img src="assets/img/faq-shape1.png" alt="image"></div>
    <div class="faq-shape2"><img src="assets/img/faq-shape2.png" alt="image"></div> -->
</section>

<!-- <section class="funfacts-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>COVID-19 Coronavirus Outbreak</h2>
        </div>
    </div>
        
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-image">
                    <img src="assets/img/funfacts2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box bg-white">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="187">00</h3>
                                <p>Total Country</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box bg-white">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="294110">00</h3>
                                <p>Confirmed Cases</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box bg-white">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="12944">00</h3>
                                <p>Deaths</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box bg-white">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="100345">00</h3>
                                <p>Recovered</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="funfacts-info color-black">
                                <p>Source: <a href="https://www.who.int/" target="_blank">WHO</a> on March 23, 2020</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="funfacts-shape1"><img src="assets/img/map-shape.png" alt="image"></div>
</section> -->

<!-- <section class="doctors-area pt-100 bg-color pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Experts </span>
            <h2>Meet Experts</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Addison Smith</h3>
                        <span>Nephrologists</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Sarah Taylor</h3>
                        <span>Infectious</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Aiken Ward</h3>
                        <span>Cardiologists</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="emergency-contact-area pt-100">
    <div class="container">
        <div class="emergency-contact-inner">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="btn-box">
                        <!--<a routerLink="/appointment" class="default-btn"><i class="flaticon-open-book"></i> Request Appointment</a>
                        <a routerLink="/doctors" class="default-btn"><i class="flaticon-doctor"></i> Meet The Doctor</a> -->
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="contact-cta">
                        <a href="tel:919502931499">
                            <i class="flaticon-call"></i>
                            <span> Contact</span>
                            +91 770 298 7668
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Blog</span>
            <h2>Worldwide Latest News</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <span class="date"><i class="flaticon-event"></i> 15 April, 2020</span>
                        <h3><a routerLink="/blog-details">Over 80 million Americans under virtual lockdown</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="small-blog-post">
                    <div class="single-small-blog-post">
                        <div class="d-flex align-items-center">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img1.jpg" alt="image"></a>
                            </div>

                            <div class="post-content">
                                <span class="date"><i class="flaticon-event"></i> 14 April, 2020</span>
                                <h3><a routerLink="/blog-details">People worldwide adjust to new life amid COVID-19</a></h3>
                            </div>
                        </div>
                    </div>

                    <div class="single-small-blog-post">
                        <div class="d-flex align-items-center">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img2.jpg" alt="image"></a>
                            </div>

                            <div class="post-content">
                                <span class="date"><i class="flaticon-event"></i> 13 April, 2020</span>
                                <h3><a routerLink="/blog-details">Coronavirus stimulus checks: What you need to know</a></h3>
                            </div>
                        </div>
                    </div>

                    <div class="single-small-blog-post">
                        <div class="d-flex align-items-center">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img3.jpg" alt="image"></a>
                            </div>

                            <div class="post-content">
                                <span class="date"><i class="flaticon-event"></i> 12 April, 2020</span>
                                <h3><a routerLink="/blog-details">New Africa coronavirus crisis looms with internal spread</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->