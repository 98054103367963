<app-onepage-navbar></app-onepage-navbar>

<div class="home-slides-two owl-carousel owl-theme">
    <div class="main-banner banner-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-content black-text">
                                <span class="sub-title">Aduri Group </span>
                                <h1>Be Informed About Aduri </h1>
                                <div class="btn-box">
                                    <a routerLink="/ " class="default-btn"><i class="flaticon-open-book"></i> Learn More</a>
                                    <a href="https://www.youtube.com/watch?v=AXvlwktbBfE" class="popup-youtube optional-btn"><i class="flaticon-play-button"></i> Watch Video</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

 <div class="main-banner banner-bg6">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-content black-text">
                                <span class="sub-title">Aduri Group </span>
                                <h1>Be Informed About Aduri </h1>
                                <div class="btn-box">
                                    <a routerLink="/ " class="default-btn"><i class="flaticon-open-book"></i> Learn More</a>
                                    <a href="https://www.youtube.com/watch?v=AXvlwktbBfE" class="popup-youtube optional-btn"><i class="flaticon-play-button"></i> Watch Video</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner banner-bg5">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-content black-text">
                                <span class="sub-title">Aduri </span>
                                <h1>Be Informed About Aburi Group </h1>
                                <div class="btn-box">
                                    <a routerLink="/ " class="default-btn"><i class="flaticon-open-book"></i> Learn More</a>
                                    <a href="https://www.youtube.com/watch?v=AXvlwktbBfE" class="popup-youtube optional-btn"><i class="flaticon-play-button"></i> Watch Video</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="about" class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/AduriChairman.JPG" alt="image">
                    <img src="assets/img/Ch320.jpeg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Aduri</span>
                    <h2>Aduri Group Company Profile</h2>
                    <p>The Aduri Group's vision is to make land speculation a low-risk high-return investment for the common man. The
                        company is keen on upholding this philosophy to ensure that every individual can satisfy their fantasy and secure
                        the future of their family The organization seeks to extend its presence in various areas, to provide 20,000 jobs and
                        financial freedom to the meriting poor.</p>
                    <p>We help our customers double the
                        investment in their venture within a
                        reasonable timeframe through
                        appropriate land speculation
                        techniques.</p>
                    <blockquote>
                        <p>We examine customer needs to
                            provide customized services. We
                            value trust & honesty which
                            consistently show in our work.</p>
                    </blockquote>
                    <p>Our vision is to make land speculation
                        a low-risk high-return investment for
                        all common man. We imagine
                        growing our administration in various
                        areas to provide 20,000+ jobs.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="cta-area">
    <div class="container">
        <div class="cta-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-md-5">
                            <img src="assets/img/Logo-01.JPG" alt="image">
                        </div>

                        <div class="col-lg-7 col-md-7">
                            <h3>We Operate 24h a day - Every Day!</h3>
                            <p>Call us if you have any problems.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="cta-btn">
                        <a href="tel:+91 770 298 7668">
                            <i class="flaticon-call"></i>
                            <span> Contact</span>
                            +91 770 298 7668
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="spread-virus-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Aduri</span>
            <h2>Aduri Group</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-spread-virus-box">
                    <img src="assets/img/RkEnclave.JPG" alt="image">
                    <h3>RK Enclave </h3>
                    <p>Every project by Aduri Group is located intelligently in the most prime locations in and around Hyderabad.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-spread-virus-box">
                    <img src="assets/img/Iconic2.JPG" alt="image">
                    <h3>ICONIC2</h3>
                    <p>Aduri Group is a Trusted by Happy customers in the last 15 Years.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-spread-virus-box">
                    <img src="assets/img/Greenest.JPG" alt="image">
                    <h3>Green Nest </h3>
                    <p>The clear title plots by Aduri Group offer you a hassle-free buying experience with immediate registration.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="Our-Ventures" class="symptoms-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="symptoms-image">
                    <img src="assets/img/Iconic2.JPG" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="symptoms-content">
                    <span class="sub-title">Aduri Group </span>
                    <h2>Completed Projects</h2>
                    <p>We have completed 50 Projects.</p>

                    <ul>
                        <li><span><i class='flaticon-tick'></i> Golden Heights</span></li>
                        <li><span><i class='flaticon-tick'></i> Archid</span></li>
                        <li><span><i class='flaticon-tick'></i> Wealth Hub</span></li>
                        <li><span><i class='flaticon-tick'></i> Zeal City</span></li>
                        <li><span><i class='flaticon-tick'></i> Sushmita Township</span></li>
                        <li><span><i class='flaticon-tick'></i> Golden City-1</span></li>
                        <li><span><i class='flaticon-tick'></i> Golden City-2</span></li>
                        <li><span><i class='flaticon-tick'></i> Green Leaf</span></li>
                        <li><span><i class='flaticon-tick'></i> Madhuranagar</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

 <!--  <section class="funfacts-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
            <h2>Aduri Coronavirus Outbreak</h2>
        </div>
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-image">
                    <img src="assets/img/funfacts.jpg" alt="image">
                </div>
            </div>

          <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="187"> </h3> 
                                <p>Total Country</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="294110">00</h3>
                                <p>Confirmed Cases</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="12944">00</h3>
                                <p>Deaths</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="100345">00</h3>
                                <p>Recovered</p>
                            </div>
                        </div>
                    </div> 
                </div> 
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="funfacts-info">
                    <p>Source: <a href="https://www.who.int/" target="_blank">WHO</a> on March 23, 2020</p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section id="projects" class="how-to-protect-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Projects</span>
            <h2>OnGoing Projects</h2>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="how-to-protect-content">
                    <div class="content">
                        <div class="text">
                            <div class="icon">
                              <!--   <i class="flaticon-hand-wash"></i>  --> 
                            </div>
                            <h3>ICONIC-2 </h3>
                            <p>Hyderabad-Bangalore Highway.</p>
                            <a class="button" routerLink="/Bangalore-Highway">Project View</a>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                            <!--    <i class="flaticon-people"></i>  --> 
                            </div> 
                            <h3>RK Enclave </h3>
                            <p>Hyderabad-Warangal Highway.</p>
                            <a class="button" routerLink="/Warangal Highway">Project View</a>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <!--  <i class="flaticon-home-plan"></i> --> 
                            </div>
                            <h3>Paradise-2 </h3>
                            <p>Hyderabad-Bangalore Highway.</p>
                            <a class="button" routerLink="/Bangalore-Highway">Project View</a>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                               <!-- <i class="flaticon-face-detection"></i> --> 
                            </div>
                            <h3>Rayan Enclave </h3>
                            <p>Hyderabad-Warangal Highway.</p>
                            <a class="button" routerLink="/Warangal Highway">Project View</a>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                               <!--  <i class="flaticon-mask"></i> --> 
                            </div>
                            <h3>GREENNest </h3>
                            <p>Farmland With Resort Facility.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                               <!--  <i class="flaticon-mask"></i> --> 
                            </div>
                            <h3>Highway Paradise</h3>
                            <p>Srisailam Highway.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                               <!--  <i class="flaticon-network"></i> -->
                            </div>
                            <h3>Paradise-1</h3>
                            <p>Hyderabad-Bangalore Highway.</p>
                            <a class="button" routerLink="/Bangalore-Highway">Project View </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="how-to-protect-image">
                    <img src="assets/img/how-to-protect/iconic26x6.jpeg" class="main-image main-image1" alt="image">
                    <img src="assets/img/how-to-protect/Rkimg01.jpeg" class="main-image main-image2" alt="image">
                    <img src="assets/img/how-to-protect/img10.jpeg" class="main-image main-image3" alt="image">
                    <img src="assets/img/how-to-protect/img09.jpeg" class="main-image main-image4" alt="image">
                    <img src="assets/img/how-to-protect/Grenest.jpeg" class="main-image main-image5" alt="image">
                    <img src="assets/img/how-to-protect/imgaduri.jpeg" class="main-image main-image6" alt="image">

                   <!--  <div class="how-to-protect-shape">
                        <img src="assets/img/how-to-protect/shape1.png" alt="image">
                        <img src="assets/img/how-to-protect/shape2.png" alt="image">
                        <img src="assets/img/how-to-protect/shape3.png" alt="image">
                        <img src="assets/img/how-to-protect/shape4.png" alt="image">
                    </div> -->
                </div>
            </div> 
        </div>
    </div>
</section>

 <!-- <section id="faq" class="faq-section-two bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Faq's</span>
            <h2>Frequently Asked & Questions</h2>
        </div>
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-book-reader'></i><span>Aduri Basics</span></a></li>
                <li><a href="#"><i class='bx bx-command'></i><span>Prevention</span></a></li>
                <li><a href="#"><i class='bx bx-user-check'></i><span>Self Quarantine</span></a></li>
                <li><a href="#"><i class='bx bx-user-pin'></i><span>For Caregivers</span></a></li>
                <li><a href="#"><i class='bx bx-info-square'></i><span>Terms to Know</span></a></li>
            </ul>
            <div class="tab-content">
                <div class="tabs-item">
                    <div class="faq-accordion accordion" id="faqAccordion">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    What is the source of the virus? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How does the virus spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Who has had Aduri spread the illness to others? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    What is community spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Will warm weather stop the outbreak of Aduri? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs-item">
                    <div class="faq-accordion accordion" id="faqAccordion2">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                    What is coronavirus? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseSix" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    What is Aduri? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    What are the symptoms of Aduri? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    What should I do if I feel sick? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    If a loved one gets sick, how can I care for them? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs-item">
                    <div class="faq-accordion accordion" id="faqAccordion3">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                                    What is the source of the virus? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseEleven" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                    How does the virus spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwelve" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                    Who has had Aduri spread the illness to others? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseThirteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                    What is community spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseFourteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                    Will warm weather stop the outbreak of Aduri? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseFifteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs-item">
                    <div class="faq-accordion accordion" id="faqAccordion4">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="true" aria-controls="collapseSixteen">
                                    What is the source of the virus? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseSixteen" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion4">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                    How does the virus spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseSeventeen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion4">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                    Who has had Aduri spread the illness to others? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseEighteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion4">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                    What is community spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseNineteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion4">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                    Will warm weather stop the outbreak of Aduri? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwenty" class="accordion-collapse collapse" data-bs-parent="#faqAccordion4">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs-item">
                    <div class="faq-accordion accordion" id="faqAccordion5">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyOne" aria-expanded="true" aria-controls="collapseTwentyOne">
                                    What is the source of the virus? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwentyOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion5">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
                                    How does the virus spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwentyTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion5">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                                    Who has had Aduri spread the illness to others? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwentyThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion5">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                                    What is community spread? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwentyFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion5">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                                    Will warm weather stop the outbreak of Aduri? <i class="flaticon-add"></i>
                                </button>
                            </div>
                            <div id="collapseTwentyFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion5">
                                <div class="accordion-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

 <section id="doctors" class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title"> Aduri Group </span>
            <h2> Managing Partners </h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/1-Director-02.jpeg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>K.M. Swamy</h3>
                        <span>Director </span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/Profile.jpeg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Aduri Ramanjaneyulu</h3>
                        <span>Chairman and MD</span>
                    </div>
                </div>
            </div>

           <!-- <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Aiken Ward</h3>
                        <span>Cardiologists</span>
                    </div>
                </div>
            </div>  -->
        </div>
    </div>
</section> 

<section class="spread-virus-area pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="spread-virus-content">
                    <span class="sub-title">Aduri Group </span>
                    <h2>WHO WE ARE</h2>
                    <p>Aduri Infra Private Limited incorporated (Aduri group) is a South Indian property development firm founded in the year 2002 and based in Hyderabad. The company develops land and sells open plot ventures in the outer landscapes of metropolitans. The company has taken up restoration of farm lands in the past few years that have been critically acclaimed. The company has developed various projects in Hyderabad and has ongoing projects in Bangalore and Vizag.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <img src="assets/img/Icon-Aduri-1.png" alt="image">
                            </div>
                            <h3>Our Vision</h3>
                            <p>Our vision is to make land speculation a low-risk high-return investment for all Indians. We imagine growing our administration in various areas to provide 10,000+ jobs and financial freedom to the meriting poor. We are growing methodically so we can successfully serve many fulfilled clients and become the number one developing land organization.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <img src="assets/img/Icon-Aduri-1.png" alt="image">
                            </div>
                            <h3>Our Mission</h3>
                            <p>We help our customers double the investment in their venture within a reasonable timeframe through appropriate land speculation techniques. This methodology includes correct planning and right topographical areas. Aduri Group puts resources into enormous packages and segments these into more modest plots so every client can manage their cost.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <img src="assets/img/Icon-Aduri-1.png" alt="image">
                            </div>
                            <h3>Our Values</h3>
                            <p>We trust in collaboration to accomplish shared objectives. We take every opportunity as a replacement challenge and strive to deliver outstanding output. We examine customer needs to provide customized services. We value trust & honesty which consistently show in our work.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="spread-virus-image">
                   <img src="assets/img/small_banner-01.jpeg" alt="image"> 
                </div>
            </div>
        </div>
    </div>

   <!-- <div class="spread-shape3"><img src="assets/img/spread-virus/spread-shape.png" alt="image"></div>  -->
</section>

<section class="emergency-contact-area pb-100">
    <div class="container">
        <div class="emergency-contact-inner">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="btn-box">
                        <!-- <a routerLink="/" class="default-btn"><i class="flaticon-open-book"></i> Request Appointment</a> -->
                        <a routerLink="/" class="default-btn"><i class="flaticon-doctor"></i> Meet Our Expert </a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="contact-cta">
                        <a href="tel:+44587154756">
                            <i class="flaticon-call"></i>
                            <span>Contact</span>
                            +91 770 298 7668
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="blog" class="blog-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Aduri </span>
            <h2>Project Brochure </h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/RkEnclave6X4.JPG" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2022</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">RK Enclave </span>
                        <h3><a routerLink="/assets/img/RK Enclave.pdf">Aduri Group is a Trusted by Happy customers in the last 15 Years</a></h3>
                       <!-- <p>  <a routerLink="/assets/img/RK Enclave.pdf" class="details-btn">Download Brochure</a>. </p> -->
                        <p> <a href="/assets/img/RK Enclave.pdf" class="details-btn" target="_blank" rel="noopener noreferrer">Download Brochure</a>.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/assets/img/Greennest.pd">
                            <img src="/assets/img/Greenest6X4.JPG" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2022</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category"> Greenest </span>
                        <h3><a routerLink="/assets/img/Greennest.pdf">Aduri Group is a Trusted by Happy customers in the last 15 Years</a></h3>
                        <p> <a href="/assets/img/Greennest.pdf" class="details-btn" target="_blank" rel="noopener noreferrer">Download Brochure</a>.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/assets/img/Iconic2.pdf">
                            <img src="/assets/img/Iconic2-6X4.JPG" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2022</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Iconic2</span>
                        <h3><a routerLink="/assets/img/Iconic2.pdf">Aduri Group is a Trusted by Happy customers in the last 15 Years</a></h3>
                        <p> <a href="/assets/img/Iconic2.pdf" class="details-btn" target="_blank" rel="noopener noreferrer">Download Brochure</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="contact" class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <h3>Drop Us A Line</h3>
                    <p>Real estate was made affordable and profitable with Adhuri’s remarkable land speculation skills. We provide HMDA, DTCP Approved Open plots in Hyderabad. The company was conferred with the ET Excellence 2021 Award for The Most Economical Plotted Developer.</p>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Name <span>*</span></label>
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Your name">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Email <span>*</span></label>
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Your email address">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Phone Number <span>*</span></label>
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your phone number">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Your Message <span>*</span></label>
                                    <textarea name="message" id="message" cols="30" rows="5" class="form-control" placeholder="Write your message..."></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn"><i class='flaticon-plane'></i> Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <h3>Here to Help</h3>
                    <p>Have a question? You may find an answer in our <a href="faq.html">FAQs</a>. But you can also contact us.</p>

                    <ul class="contact-list">
                        <li><i class='bx bx-map'></i> Location: <a href="#">Marketed By, PIL4U.COM,  Wanaparthy</a></li>
                        <li><i class='bx bx-phone-call'></i> Call Us: <a href="tel:++91 770 298 7668">+91 770 298 7668</a></li>
                        <li><i class='bx bx-envelope'></i> Email Us: <a href="mailto:info@pil4u.com">info@pil4u.com</a></li>
                        <li><i class='bx bx-microphone'></i> Fax: <a href="tel:++91 770 298 7668">+91 770 298 7668</a></li>
                    </ul>

                    <!--<h3>Opening Hours:</h3>
                    <ul class="opening-hours">
                        <li><span>Monday:</span> 8AM - 6AM</li>
                        <li><span>Tuesday:</span> 8AM - 6AM</li>
                        <li><span>Wednesday:</span> 8AM - 6AM</li>
                        <li><span>Thursday - Friday:</span> 8AM - 6AM</li>
                        <li><span>Sunday:</span> Closed</li>
                    </ul> -->

                    <h3>Follow Us:</h3>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/AduriGroupOfficial" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/AduriGroup_Off" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.instagram.com/adurigroupofficial/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/adurigroupofficial/" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-skype'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="https://www.youtube.com/c/aduriinfra" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>