<!-- <app-header-one></app-header-one> -->

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Products</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Products</li>
            </ul>
        </div>
    </div>
</section>

<section class="products-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Medical Mask</a></h3>
                        <div class="price">
                            <span class="new">$18</span>
                        </div>
                        <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img2.jpg" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Antiseptics</a></h3>
                        <div class="price">
                            <span class="new">$20</span>
                        </div>
                        <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img3.jpg" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Medical Gloves</a></h3>
                        <div class="price">
                            <span class="new">$15</span>
                            <span class="old">$20</span>
                        </div>
                        <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img4.jpg" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Drugs</a></h3>
                        <div class="price">
                            <span class="new">$10</span>
                        </div>
                        <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img5.jpg" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Asprin</a></h3>
                        <div class="price">
                            <span class="new">$18</span>
                        </div>
                        <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img6.jpg" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Sanitizer</a></h3>
                        <div class="price">
                            <span class="new">$18</span>
                            <span class="old">$20</span>
                        </div>
                        <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img7.jpg" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Black Mask</a></h3>
                        <div class="price">
                            <span class="new">$25</span>
                        </div>
                        <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/products-details">
                            <img src="assets/img/products/img8.jpg" alt="image">
                        </a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/products-details">Hand Sanitizer</a></h3>
                        <div class="price">
                            <span class="new">$8</span>
                        </div>
                        <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <a routerLink="/products" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/products" class="page-numbers">2</a>
                    <a routerLink="/products" class="page-numbers">3</a>
                    <a routerLink="/products" class="page-numbers">4</a>
                    <a routerLink="/products" class="page-numbers">5</a>
                    <a routerLink="/products" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>