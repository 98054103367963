<app-header-three></app-header-three>

<section class="hero-banner">
    <div class="container">
        <div class="hero-banner-content">
            <h1>COVID-19 Live Update</h1>
            <div class="banner-box-list">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-banner-box">
                            <div class="icon">
                                <img src="assets/img/icon5.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="196">00</h3>
                            <p>Total Country</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-banner-box">
                            <div class="icon">
                                <img src="assets/img/icon6.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="375498">00</h3>
                            <p>Confirmed Cases</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-banner-box">
                            <div class="icon">
                                <img src="assets/img/icon7.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="16362">00</h3>
                            <p>Deaths</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-banner-box">
                            <div class="icon">
                                <img src="assets/img/icon8.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="109102">00</h3>
                            <p>Recovered</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src="assets/img/banner-map.png" alt="image">
        </div>
    </div>
</section>

<section class="symptoms-section bg-white-color pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Symptoms</span>
            <h2>Corona Virus Symptoms</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="symptoms-box-list">
                    <div class="box-item">
                        <div class="img">
                            <img src="assets/img/symptoms-icon1.png" alt="image">
                        </div>
                        <h3>Cough</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>

                    <div class="box-item">
                        <div class="img">
                            <img src="assets/img/symptoms-icon2.png" alt="image">
                        </div>
                        <h3>Strong Headache</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>

                    <div class="box-item">
                        <div class="img">
                            <img src="assets/img/symptoms-icon3.png" alt="image">
                        </div>
                        <h3>Confusion</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="symptoms-img">
                    <img src="assets/img/symptoms-img2.png" alt="image">
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="symptoms-box-list">
                    <div class="box-item">
                        <div class="img">
                            <img src="assets/img/symptoms-icon4.png" alt="image">
                        </div>
                        <h3>Hot Fever</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>

                    <div class="box-item">
                        <div class="img">
                            <img src="assets/img/symptoms-icon5.png" alt="image">
                        </div>
                        <h3>Shortness Of Breath</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>

                    <div class="box-item">
                        <div class="img">
                            <img src="assets/img/symptoms-icon6.png" alt="image">
                        </div>
                        <h3>Sore Throat</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="symptoms-shape1"><img src="assets/img/symptoms-shape1.png" alt="image"></div>
    <div class="symptoms-shape2"><img src="assets/img/symptoms-shape2.png" alt="image"></div>
</section>

<section class="about-section pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about-img3.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Covid-19</span>
                    <h2>About Coronavirus Disease</h2>
                    <p>In 2019, the Centers for Disease Control and Prevention (CDC) started monitoring the outbreak of a new coronavirus, SARS-CoV-2, which causes the respiratory illness now known as COVID-19. Authorities first identified the virus in Wuhan, China.</p>
                    <p>More than 78,191 people have contracted the virus in China. Health authorities have identified many other people with COVID-19 around the world, including in the United States. On January 31, 2020, the virus passed from one person to another in the U.S.</p>
                    <blockquote>
                        <p>The World Health Organization (WHO) have declared a public health emergency relating to COVID-19.</p>
                    </blockquote>
                    <p>Since then, this strain has been diagnosed in several U.S. residents. The CDC have advised that it is likely to spread to more people. COVID-19 has started causing disruption in at least 25 other countries.</p>

                    <a routerLink="/about-two" class="default-btn"><i class="flaticon-open-book"></i> Know More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="about-shape1"><img src="assets/img/about-shape2.png" alt="image"></div>
</section>

<section class="spread-virus-area pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="spread-virus-content">
                    <span class="sub-title">Spreads</span>
                    <h2>How Covid-19 Spreads</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <img src="assets/img/spread-virus/icon1.png" alt="image">
                            </div>
                            <h3>Human Contact</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <img src="assets/img/spread-virus/icon2.png" alt="image">
                            </div>
                            <h3>Air Transmission</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        </li>
                        <li>
                            <div class="icon">
                                <img src="assets/img/spread-virus/icon3.png" alt="image">
                            </div>
                            <h3>Contaminated Objects</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="spread-virus-image">
                    <img src="assets/img/spread-virus/spread-img.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="spread-shape3"><img src="assets/img/spread-virus/spread-shape.png" alt="image"></div>
</section>

<section class="prevention-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Prevention</span>
            <h2>How to Protect Yourself</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="prevention-content">
                    <div class="title">
                        <h3>Things You Should Do</h3>
                    </div>

                    <div class="content-list">
                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon1.png" alt="image">
                            </div>
                            <h3>Wash Your Hand For 20 Sec</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon2.png" alt="image">
                            </div>
                            <h3>Wear Mask All The Time</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon3.png" alt="image">
                            </div>
                            <h3>Avoid Contact With Animals</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon4.png" alt="image">
                            </div>
                            <h3>Always Cover Your Sneeze</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="prevention-content">
                    <div class="title">
                        <h3>Things You Shouldn’t Do</h3>
                    </div>

                    <div class="content-list">
                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon5.png" alt="image">
                            </div>
                            <h3>Avoid Crowded Places</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon6.png" alt="image">
                            </div>
                            <h3>Don't Handshake</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon7.png" alt="image">
                            </div>
                            <h3>Don't Touch Your Face</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon8.png" alt="image">
                            </div>
                            <h3>Avoid Travel</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="prevention-shape1"><img src="assets/img/prevention-shape1.png" alt="image"></div>
    <div class="prevention-shape2"><img src="assets/img/prevention-shape2.png" alt="image"></div>
    <div class="prevention-shape3"><img src="assets/img/prevention-shape3.png" alt="image"></div>
    <div class="prevention-shape4"><img src="assets/img/prevention-shape4.png" alt="image"></div>
</section>

<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Doctors</span>
            <h2>Meet Specialist Doctors</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Addison Smith</h3>
                        <span>Nephrologists</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Sarah Taylor</h3>
                        <span>Infectious</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Aiken Ward</h3>
                        <span>Cardiologists</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-section pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Faq's</span>
            <h2>Frequently Asked & Questions</h2>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion accordion" id="faqAccordion">
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What is the source of the virus? <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                How does the virus spread? <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Who has had COVID-19 spread the illness to others? <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                What is community spread? <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Will warm weather stop the outbreak of COVID-19? <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion accordion" id="faqAccordion2">
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                What is coronavirus? <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseSix" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion2">
                            <div class="accordion-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                What is COVID-19? <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                            <div class="accordion-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                What are the symptoms of COVID-19? <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                            <div class="accordion-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                What should I do if I feel sick? <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                            <div class="accordion-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                If a loved one gets sick, how can I care for them? <i class="flaticon-add"></i>
                            </button>
                        </div>
                        <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                            <div class="accordion-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="faq-img">
            <img src="assets/img/faq-img2.jpg" alt="image">
            <a href="https://www.youtube.com/watch?v=BtN-goy9VOY" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
        </div>
    </div>
    <div class="faq-shape1"><img src="assets/img/faq-shape1.png" alt="image"></div>
    <div class="faq-shape2"><img src="assets/img/faq-shape2.png" alt="image"></div>
</section>

<section class="emergency-contact-area pt-100">
    <div class="container">
        <div class="emergency-contact-inner">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="btn-box">
                        <a routerLink="/appointment" class="default-btn"><i class="flaticon-open-book"></i> Request Appointment</a>
                        <a routerLink="/doctors" class="default-btn"><i class="flaticon-doctor"></i> Meet The Doctor</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="contact-cta">
                        <a href="tel:+44587154756">
                            <i class="flaticon-call"></i>
                            <span>Emergency Contact</span>
                            +44 587 154756
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Blog</span>
            <h2>Worldwide Latest News</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <span class="date"><i class="flaticon-event"></i> 15 April, 2020</span>
                        <h3><a routerLink="/blog-details">Over 80 million Americans under virtual lockdown</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="small-blog-post">
                    <div class="single-small-blog-post">
                        <div class="d-flex align-items-center">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img1.jpg" alt="image"></a>
                            </div>

                            <div class="post-content">
                                <span class="date"><i class="flaticon-event"></i> 14 April, 2020</span>
                                <h3><a routerLink="/blog-details">People worldwide adjust to new life amid COVID-19</a></h3>
                            </div>
                        </div>
                    </div>

                    <div class="single-small-blog-post">
                        <div class="d-flex align-items-center">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img2.jpg" alt="image"></a>
                            </div>

                            <div class="post-content">
                                <span class="date"><i class="flaticon-event"></i> 13 April, 2020</span>
                                <h3><a routerLink="/blog-details">Coronavirus stimulus checks: What you need to know</a></h3>
                            </div>
                        </div>
                    </div>

                    <div class="single-small-blog-post">
                        <div class="d-flex align-items-center">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img3.jpg" alt="image"></a>
                            </div>

                            <div class="post-content">
                                <span class="date"><i class="flaticon-event"></i> 12 April, 2020</span>
                                <h3><a routerLink="/blog-details">New Africa coronavirus crisis looms with internal spread</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>